<template>
	<div>
		<el-form
			ref="queryForm"
			class="query-box"
			v-model="queryParams"
			label-width="68px"
		>
		<el-row :gutter="20">
			<el-col :span="6">
				<el-form-item label="创建时间" prop="name" >
					<el-date-picker
						size="small"
						v-model="createDateRange"
						type="daterange"
						@change="dateChange"
						range-separator="-"
						value-format="yyyy-MM-dd"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						style="width: 100%;"
					>
					</el-date-picker>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item
					label="代理商/机构编号"
					prop="userNo"
					label-width="114px"
				>
					<el-input
						v-model="queryParams.userNo"
						clearable
						placeholder="请输入代理商/机构编号"
						size="small"
						@keyup.enter.native="handleQuery"
					/>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item label="止付订单" prop="orderNo">
					<el-input
						v-model="queryParams.orderNo"
						clearable
						placeholder="请输入止付订单"
						size="small"
						@keyup.enter.native="handleQuery"
					/>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item label="止付类型" prop="name">
					<el-select
						v-model="queryParams.suspendType"
						placeholder="请选择止付类型"
						size="small"
						style="width: 100%"
					>
						<el-option label="全部" value=""></el-option>
						<el-option label="机具款项" value="1"></el-option>
						<el-option label="风险止付" value="2"></el-option>
						<el-option label="活动止付" value="3"></el-option>
					</el-select>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="6">
				<el-form-item label="止付状态" prop="name">
					<el-select
						v-model="queryParams.status"
						placeholder="请选择交易状态"
						size="small"
						style="width: 100%"
					>
						<el-option label="全部" value=""></el-option>
						<el-option label="初始化" value="INIT"></el-option>
						<el-option label="止付中" value="WAITING"></el-option>
						<el-option label="已完成" value="COMPLETE"></el-option>
						<el-option label="已解除" value="REMOVE"></el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item label="备注" prop="remark" label-width="114px">
					<el-input
						v-model="queryParams.remark"
						clearable
						placeholder="请输入备注"
						size="small"
						@keyup.enter.native="handleQuery"
					/>
				</el-form-item>
			</el-col>
		</el-row>
			
			<!-- <el-form-item label="完成时间" prop="name" label-width="120px">
				<el-date-picker
					size="small"
					v-model="updateDateRange"
					type="daterange"
					@change="dateChange"
					range-separator="-"
					value-format="yyyy-MM-dd"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				>
				</el-date-picker>
			</el-form-item> -->
		</el-form>

		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button
					type="primary"
					icon="el-icon-search"
					size="mini"
					@click="handleQuery"
					v-permission="'SUSPEND_ORDER_QUERY'"
				>
					查询
				</el-button>
				<el-button
					icon="el-icon-refresh"
					size="mini"
					@click="handleResetQuery"
					v-permission="'SUSPEND_ORDER_QUERY'"
				>
					重置
				</el-button>
				<el-button
					type="primary"
					size="mini"
					@click="handleAdd"
					v-permission="'SUSPEND_ORDER_INSERT'"
				>
					新增止付
				</el-button>
				<el-button
					icon="el-icon-download"
					size="mini"
					@click="handleExport"
					v-permission="'AGENT_ACCOUNT_EXPORT'"
				>
					导出
				</el-button>
			</el-col>
		</el-row>
		<CustomForm
			:dicts="dicts"
			:on-fresh="showCustomForm"
			@on-close="handlerCustomFormOnclose"
			:isAdd="true"
		></CustomForm>
	</div>
</template>

<script>
import CustomForm from "./CustomForm";
import { FinancialToolsApi } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: {}
		}
	},
	components: { CustomForm },
	data() {
		return {
			queryParams: {},
			createDateRange: [],
			updateDateRange: [],
			showCustomForm: false,
			dateVal: ""
		};
	},
	mounted() {},
	methods: {
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.commitChange();
		},
		handleQuery() {
			this.commitChange();
		},
		handleResetQuery() {
			this.queryParams = {};
			this.createDateRange = [];
			this.updateDateRange = [];
			this.commitChange();
		},
		handleAdd() {
			this.showCustomForm = true;
		},
		handleExport() {
			this.$confirm("确定导出止付订单", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(async () => {
				this.dealWithQueryParams();
				let result = await FinancialToolsApi.suspendOrder.export(
					this.queryParams
				);
				if (result.success) {
					this.downloadFile(result.data);
				}
			});
		},
		dealWithQueryParams() {
			let [startCreateTime, endCreateTime] = this.createDateRange;
			this.queryParams.startCreateTime =
				startCreateTime && startCreateTime + " 00:00:00";
			this.queryParams.endCreateTime =
				endCreateTime && endCreateTime + " 23:59:59";

			let [beginCompleteTime, endCompleteTime] = this.updateDateRange;
			this.queryParams.beginCompleteTime =
				beginCompleteTime && beginCompleteTime + " 00:00:00";
			this.queryParams.endCompleteTime =
				endCompleteTime && endCompleteTime + " 23:59:59";
		},
		commitChange() {
			this.dealWithQueryParams();
			let params = { ...this.queryParams };
			this.$emit("on-change", params);
		},
		dateChange(val) {
			this.queryParams.transTime = JSON.parse(JSON.stringify(val)).join(
				","
			);
		}
	}
};
</script>

<style scoped></style>
